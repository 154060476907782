import LogoLight from '#images/common/logo.svg';
import LogoDark from '#images/common/logodark.svg';
import { useEffect, useState } from 'react';
import { prepareUrl } from '#utils/prepareUrl';

type Props = {
  currentLanguage: string;
  style: string;
  templateType: string;
};

const testPaths = ['/pri4in', '/pri4tr', '/pri5in', '/pri10in', '/ppc'];

const LogoLink = ({ currentLanguage, style, templateType }: Props) => {
  const [logoLink, setLogoLink] = useState('');
  const isDarkFooter = templateType.includes('dark');

  useEffect(() => {
    const location = window.location.pathname;
    const matchedPath = testPaths.find((path) => location.includes(path)) || '/';
    setLogoLink(prepareUrl(matchedPath, currentLanguage));
  }, []);

  return (
    <a aria-label="logo" href={logoLink} className={style}>
      <img
        src={isDarkFooter ? LogoLight.src : LogoDark.src}
        width="177"
        height="32"
        alt="Scannero logo"
        loading="eager"
      />
    </a>
  );
};

export default LogoLink;
